<template>
  <div id="app">
    <template v-if="!/checkout|voice|ordercompleted|GroupCatalogForCustomer|ProductCatalogForCustomer/i.test($route.name)">
      <Header
        :parentData="isHomepage ? 'mainHeader' : 'whiteHeader'"
        :fixed="isHomepage"
      />
      <router-view />
      <Footer />
    </template>
    <router-view v-else />
    <template v-if="!/checkout|ProductDetail|OrderDetail|Voice|GroupCatalogForCustomer|ProductCatalogForCustomer/i.test($route.name) && userData">
      <FloatWhatsapp />
      <Chat />
    </template>
    <b-modal id="modal-loading" modal-class="d-none" hide-backdrop hide-header hide-footer @shown="$bvModal.hide('modal-loading')" />
  </div>
</template>
<script>
import { toaster } from "@/_helpers";
import { session, qbHelper } from "@/_services";
import Header from "@/components/mains/MainHeader";
import Footer from "@/components/mains/Footer";
import Chat from "@/components/mains/Chat.vue";
import FloatWhatsapp from "@/components/mains/FloatWhatsapp.vue";
import moment from "moment";
import QB from "quickblox/quickblox.min";
import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import { getMessaging, getToken } from "firebase/messaging";

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    Chat,
    FloatWhatsapp,
  },
  metaInfo() {
    const pageTitle = 'Situs Perhiasan Emas Berlian Online Terpercaya | Whizliz.com'
    const pageDescription = 'Toko perhiasan emas berlian online terpercaya. Kalung, cincin, anting, gelang, liontin, logam mulia dari Juene, Aurum, Lino, Lotus, Ayu, Antam, Lotusarchi.'
    return {
      title: pageTitle,
      link: [
        {vmid: 'canonical', rel: 'canonical', href: process.env.VUE_APP_FE_URL + this.$route.path.substring(1)}
      ],
      meta: [
        {vmid: 'description', name: 'description', content: pageDescription},
        // facebook
        {vmid: 'og:type', property: 'og:type', content: 'website'},
        {vmid: 'og:url', property: 'og:url', content: process.env.VUE_APP_FE_URL + this.$route.path.substring(1)},
        {vmid: 'og:title', property: 'og:title', content: pageTitle},
        {vmid: 'og:description', property: 'og:description', content: pageDescription},
        {vmid: 'og:site_name', property: 'og:site_name', content: 'Whizliz'},
        {vmid: 'og:image', property: 'og:image', content: ''},
        // twitter
        {vmid: 'twitter:card', name: 'twitter:card', content: 'summary_large_image'},
        {vmid: 'twitter:url', name: 'twitter:url', content: process.env.VUE_APP_FE_URL + this.$route.path.substring(1)},
        {vmid: 'twitter:title', name: 'twitter:title', content: pageTitle},
        {vmid: 'twitter:description', name: 'twitter:description', content: pageDescription},
        {vmid: 'twitter:image', name: 'twitter:image', content: ''},
      ],
      changed(metaInfo) {
        firebase.analytics().logEvent('screen_view', { firebase_screen: metaInfo.title });
      }
    }
  },
  computed: {
    isHomepage() {
      return /homepage|RegisterResellerReferral|BPRegistration|FindOurBP/i.test(this.$route.name);
    },
    firebaseConfig() {
      return JSON.parse(process.env.VUE_APP_FIREBASE_CONFIG);
    },
    vapidKey() {
      return process.env.VUE_APP_VAPID_KEY;
    },
    firebaseAuthKey() {
      return process.env.VUE_APP_FIREBASE_AUTH;
    },
    userData() {
      return this.$store.getters.getUserData;
    },
    isLoggedIn() {
      return this.$store.getters.getIsLoggedIn;
    },
    dialogList() {
      return this.$store.getters.getDialogList;
    },
    currentDialog() {
      return this.$store.getters.getCurrentDialog;
    },
    messageList() {
      return this.$store.getters.getMessageList;
    },
    // total all dialogs unread counter
    totalUnreadCounter: function () {
      let total = 0;
      for (const val of Object.values(this.dialogList)) {
        total = total + val.unread_messages_count;
      }
      return total;
    },
  },
  mounted() {
    this.$bvModal.show('modal-loading');
    this.registerFirebase(); //* initialize Firebase App
    this.checkSession();
    qbHelper.initQuickblox();
    this.qbCheckSession();
  },
  methods: {
    qbCheckSession() {
      if (!this.isLoggedIn) return
      
      if (!this.userData) return setTimeout(() => {
        this.qbCheckSession();
      }, 1000);
      else {
        this.$firebaseSetUserProperties({
          gender: this.userData.gender,
          age: this.getUserAge(this.userData.birth_date)
        });
      } 

      if (!qbHelper.getSession() && this.userData?.quickblox_id) qbHelper.createSession()
    },
    async registerFirebase() {
      try {
        if (!firebase.apps.length) firebase.initializeApp(this.firebaseConfig);
        
        if (firebase.messaging.isSupported()) {
          const token = await getToken(getMessaging(), { vapidKey: this.vapidKey });
          if(token) {
            const body = { topic: 'webpush', token };
            this.$api.firebase.subscribeFCM(body);
          } else console.error("Unable to get permission to notify.")
        }
      } catch (error) {
        console.error(error);
      }
    },
    async subscribePushNotifQBChat(data) {
      if (firebase.messaging.isSupported()) {
        const messaging = getMessaging()
        const deviceToken = await getToken(messaging, {vapidKey: this.vapidKey}).catch(() => {});
        if (deviceToken) {
          const body = {
            topic: `qb-${data.quickblox_id}`,
            token: deviceToken
          }
          this.$api.firebase.subscribeFCM(body);
        }
      }
    },
    async subscribeFirebaseTopic(userId) {
      try {
        if (firebase.messaging.isSupported()) {
          const messaging = getMessaging()
          const browserToken = await getToken(messaging, {vapidKey: this.vapidKey})
          const body = {
            topic: `customer-${userId}`,
            token: browserToken
          }
          this.$api.firebase.subscribeFCM(body);
          // if (browserToken) {
          //   axios.post(`https://iid.googleapis.com/iid/v1/${browserToken}/rel/topics/${process.env.VUE_APP_API_URL.indexOf('staging') < 0 ? '' : 'staging-'}customer-${userId}`,
          //     {}, {headers: {authorization: "key=" + this.firebaseAuthKey}}
          //   )
          // }
        }
      } catch (e) {
        console.error(e);
      }
    },
    async checkSession() {
      const hasSession = await session()
      if (hasSession) return
      else if (this.$store.getters.getUserToken)
        toaster.make("Please relogin", "danger");
      else this.$store.dispatch("resetUserState");
    },

    // Quickblox listener
    async onMessageListener(userId, message) {
      if (userId === this.userData.quickblox_id) return false;

      message = await qbHelper.fillNewMessageParams(userId, message);

      if (message.markable && userId !== this.userData.quickblox_id) qbHelper.sendDeliveredStatus(userId, message.id, message.dialog_id);
      
      // TODO foreground notification lebih baik pake onMessage FCM
      // if browser tab is active show foreground notification
      if (!document.hidden) {
        this.showNotification(message);
      }

      // Update existing dialog
      if (this.dialogList[message.chat_dialog_id]) this.$store.dispatch("setDialogParams", message);
      // get all dialogs if dialog not found (new dialog)
      else qbHelper.getDialogs(); 

      if (message.chat_dialog_id === this.currentDialog?._id) {
        const newMessage = await qbHelper.setMessages(message);
        this.$store.dispatch("pushNewMessage", newMessage);
        setTimeout(() => {
          qbHelper.scrollTo(document.querySelector(".dialog-chat-messages-list"), "bottom");
        }, 200);
      }
    },

    onReadStatusListener(messageId, dialogId) {
      if (dialogId === this.currentDialog._id) {
        for (const messages of Object.values(this.messageList)) {
          messages.forEach((message) => {
            if (message._id === messageId) {
              this.$store.dispatch("setMessageStatus", message);
            }
          });
        }
      }
    },

    // Quickblox foreground notification
    showNotification(message) {
      console.log("showNotification()", message);
      // get sender name for message for new dialog
      qbHelper.getUserList(
        {
          field: "id",
          value: message.sender_id,
        },
        (res) => {
          if (res) {
            console.log("res getUserList");
            // if dialog not exist use res[0].full_name for the sender name
            const senderName = this.dialogList[message.chat_dialog_id]
              ? this.dialogList[message.chat_dialog_id].name
              : res[0].full_name;

            // Play audio for foreground chat notification
            const audio = new Audio(require("@/assets/audio/notification.mp3"));
            audio.play();

            const h = this.$createElement;
            // Custom title
            const vNodesTitle = h(
              "div",
              {
                class: [
                  "d-flex",
                  "flex-grow-1",
                  "justify-content-between",
                  "align-items-center",
                ],
              },
              [
                h("strong", "New Message!"),
                h(
                  "small",
                  { class: "mr-2" },
                  moment.unix(message.date_sent).format("HH:mm").toString()
                ),
              ]
            );

            // Custom message
            const vNodesMsg = h(
              "div",
              {
                class: ["d-flex", "flex-column"],
              },
              [
                h("strong", { class: "mb-1" }, senderName),
                h(
                  "div",
                  /WHIZPVL#/.test(message.message)
                    ? `Product: ${message.message.split("#")[3]}`
                    : /WHIZPVLO#/.test(message.message)
                    ? `Order: ${message.message.split("#")[1]}`
                    : message.message
                ),
              ]
            );

            toaster.make(vNodesMsg, "info", vNodesTitle);
          }
        }
      );
    },

    // formatNotificationTimestamp(value) {
    //   return value ? moment.unix(value).format("HH:mm").toString() : value;
    // },
    //* Ref: https://www.javatpoint.com/calculate-age-using-javascript
    getUserAge(birthDate) {
      if (!birthDate) return;
      const ageDate = new Date(Date.now() - new Date(birthDate).getTime());
      const age = ageDate.getUTCFullYear() - 1970;
      return age >= 0 ? age : 0;
    },
  },
  watch: {
    "$route.path"() {
      window.scrollTo(0, 0);
    },
    $route(to) {
      const excludedRoute =
        /DetailProfile|CheckoutRedeem|Checkout|EditProfile|RecentOrderProfile|MyAddress|MyLizPoint|ProductReview|OrderDetail|RegisterReferral/i.test(
          to.name
        );
      if (!excludedRoute) this.checkSession();
    },
    isLoggedIn(status) {
      const userData = this.userData
      if (status && userData) {
        this.$firebaseSetUserProperties({
          gender: userData.gender,
          age: this.getUserAge(userData.birth_date)
        });
        this.subscribeFirebaseTopic(userData.id)
        if (userData.quickblox_id) {
          if (!qbHelper.getSession()) qbHelper.createSession(userData);
          else qbHelper.loginSession();
          this.subscribePushNotifQBChat(userData);
        }
      }
    },
    "$store.getters.getQbSession"() {
      if (!this.userData?.quickblox_id) return
      qbHelper.getDialogs();
      QB.chat.onMessageListener = this.onMessageListener.bind(this);
      QB.chat.onReadStatusListener = this.onReadStatusListener.bind(this);
    },

    // get message list when dialog is selected / changed
    currentDialog(dialog) {
      if (!dialog?._id) return
      qbHelper.getMessages(dialog._id);
    },

    // Change unread message counter on document title
    totalUnreadCounter(val) {
      // pattern regex for : (n)
      const pattern = /^\(\d+\)/;

      if (pattern.test(document.title)) {
        document.title = document.title.replace(
          pattern,
          val > 0 ? `(${val})` : ""
        );
      } else document.title = `(${val}) ${document.title}`;
      
    },
  },
};
</script>
